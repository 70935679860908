<template>
  <b-card
    v-if="user"
    text-variant="center"
    class="card card-congratulations"
  >
    <!-- images -->
    <b-img
      :src="require('@/assets/images/elements/decore-left.png')"
      class="congratulations-img-left"
    />
    <b-img
      :src="require('@/assets/images/elements/decore-right.png')"
      class="congratulations-img-right"
    />
    <!--/ images -->

    <b-avatar
      variant="primary"
      size="70"
      class="shadow mb-2"
    >
      <feather-icon
        size="28"
        icon="AwardIcon"
      />
    </b-avatar>
    <h1
      style="text-transform: capitalize"
      class="mb-1 mt-50 text-white"
    >
      Hoş Geldin {{ user.fullname }},
    </h1>
    <b-card-text class="m-auto w-75">
      Soldaki <strong>menüden</strong> sistemi kullanmaya başlayabilirsin.
    </b-card-text>
  </b-card>
</template>

<script>
export default {
  computed: {
    user() {
      return this.$store.getters.user
    },
  },
}
</script>
