<template>
  <section id="dashboard-analytics">
    <b-row>
      <b-col
        lg="4"
        md="12"
      />
      <b-col
        lg="4"
        md="12"
      >
        <analytics-congratulation />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import AnalyticsCongratulation from './AnalyticsCongratulation.vue'

export default {
  components: {
    AnalyticsCongratulation,
  },
  computed: {
    user() {
      return this.$store.getters.user
    },
  },
}
</script>
